<div *ngIf="!hideFooter">
    <footer class="footer footer-bar">
        <div class="footer-py-30">
            <div class="container text-center">
                <div class="row align-items-center">
                    <div class="col-sm-6">
                        <div class="text-sm-start">
                            <p class="mb-0">©
                                {{year}} Nalaminds 
                            </p>
                        </div>
                    </div>

                    <div class="col-sm-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <ul class="list-unstyled social-icon foot-social-icon text-sm-end mb-0">
                            <li class="list-inline-item ms-1"><a href="https://www.linkedin.com/company/nalaminds/" class="rounded" target="_blank">
                                    <i-feather name="linkedin" class="fea icon-sm fea-social"></i-feather></a>
                            </li>
                        </ul><!--end icon-->
                    </div><!--end col-->
                </div><!--end row-->
            </div><!--end container-->
        </div>
    </footer>
    <!-- Footer End -->
</div>