import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HelpcenterOverviewComponent } from './core/components/helpcenter-overview/helpcenter-overview.component';

import { MasterPageComponent } from './core/components/master-page/master-page.component';
import { TypformComponent } from './typform/typform.component';


const routes: Routes = [
  {
    path: '',
    component: MasterPageComponent,
    children: [
      { path: '', component: HelpcenterOverviewComponent }
    ]
  },
  { path: 'form', component: TypformComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: "top",
  scrollOffset: [0, 0],
  // Enable scrolling to anchors
  anchorScrolling: "enabled"})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
