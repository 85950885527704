import { Component } from '@angular/core';

@Component({
  selector: 'app-our-solutions',
  templateUrl: './our-solutions.component.html',
  styleUrls: ['./our-solutions.component.css']
})
export class OurSolutionsComponent {

}
