// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyApcc-5ftqDUGUwbqgDwZ5H7Nm0Ox5NNSk",
  authDomain: "autopilot-nalaminds.firebaseapp.com",
  projectId: "autopilot-nalaminds",
  storageBucket: "autopilot-nalaminds.appspot.com",
  messagingSenderId: "189028006083",
  appId: "1:189028006083:web:f4934c0fe98a2fc5914a76",
  measurementId: "G-4P7BL9V22L"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
