import { Component, HostListener } from '@angular/core';


@Component({
  selector: 'app-nala-autopilot',
  templateUrl: './nala-autopilot.component.html',
  styleUrls: ['./nala-autopilot.component.css']
})
export class NalaAutopilotComponent {


}
