import { Component } from '@angular/core';

@Component({
  selector: 'app-typform',
  templateUrl: './typform.component.html',
  styleUrls: ['./typform.component.css']
})
export class TypformComponent {

}
