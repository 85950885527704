<!-- Navbar STart -->
<header id="topnav" class="defaultscroll sticky" (window:scroll)="windowScroll()" [class]="navClass"
  [ngClass]="{'tagline-height': sliderTopbar == true}">
  <div class="container">
    <!-- Logo container-->
    <div>
      <a class="logo" routerLink="" *ngIf="navClass !== 'nav-light'">
        <img alt="" class="l-dark" height="64" id="logo-light-mode" ngSrc="assets/images/nala/logos/main.svg" width="209">
        <img alt="Nalaminds logo" class="showing" height="96" id="logo-all-white" ngSrc="/assets/images/nala/logos/all_white.svg" width="314"/>
<!--        <img src="assets/images/nala/logos/blue_white.svg" class="logo-dark-mode" height="64" alt="">-->
      </a>
    </div>
  </div>
  <div class="right-button">
    <app-demo-button url="https://form.typeform.com/to/lhOJKZWe?typeform-source=b9vrx373fpj.typeform.com&utm-content=navbar"></app-demo-button>
  </div>
  <!--end container-->
</header>
<!--end header-->
