<div class="outer" >
    <div class="object-icon obj-p1">
        <span class="h2 me-2">
            <i class="uil uil-brackets-curly"></i>
          </span>
    </div>
    <div class="object-icon obj-p2">
        <span class="h2 me-2">
            <i class="uil uil-brain"></i>
          </span>
    </div>
    <div class="object-icon obj-p3">
        <span class="h2 me-2">
            <i class="uil uil-code-branch"></i>
          </span>
    </div>
    <div class="object-icon obj-p4">
        <span class="h2 me-2">
            <i class="uil uil-arrow"></i>
          </span>
    </div>
    <div class="object-icon obj-p5">
        <span class="h2 me-2">
            <i class="uil uil-rocket"></i>
          </span>
    </div>
    <div class="object-icon obj-p6">
        <span class="h2 me-2">
            <i class="uil uil-cell"></i>
          </span>
    </div>

    <div class="inner">
        <div class="object-icon obj-p1">
            <span class="h2 me-2">
                <i class="uil uil-channel"></i>
              </span>
        </div>
        <div class="object-icon obj-p2">
            <span class="h2 me-2">
                <i class="uil uil-cloud-share"></i>
              </span>
        </div>
        <div class="object-icon obj-p3">
            <span class="h2 me-2">
                <i class="uil uil-robot"></i>
              </span>
        </div>
        <div class="object-icon obj-p4">
            <span class="h2 me-2">
                <i class="uil uil-desktop"></i>
              </span>
        </div>
        <div class="center-image">
            <img src="assets/images/main_icon_blue.png" alt="">
        </div>
    </div>
</div>
  
