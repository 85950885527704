import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-typewriter',
  template: `{{text}}`,
  styleUrls: ['./typewriter.component.css']
})
export class TypewriterComponent implements OnInit {
  text = '';
  private targetTexts = [ 
    'desenvolvimento.',
    'crescimento.',
    'sucesso.',
    'negócio.'
  ];
  private currentTextIndex = 0;
  private isDeleting = false;

  ngOnInit() {
    this.typeWriterEffect();
  }

  typeWriterEffect() {
    this.typeAndDeleteText();
  }

  typeAndDeleteText(i = 0) {
    let currentTargetText = this.targetTexts[this.currentTextIndex];
    const typingSpeed = this.getRandomMs(50, 100);

    // Se estiver no final do texto e não estiver apagando, comece a apagar
    if (i === currentTargetText.length && !this.isDeleting) {
      this.isDeleting = true;
      setTimeout(() => this.typeAndDeleteText(i), 600); // Espera um pouco antes de começar a apagar
    }
    // Se estiver apagando, remova um caractere
    else if (this.isDeleting && this.text.length > 0) {
      this.text = this.text.slice(0, this.text.length - 1);

      if(this.text.length === 0){
        this.isDeleting = false; // Se já apagou tudo, pode parar de apagar.
        this.currentTextIndex++; // Mova para o próximo texto

        if (this.currentTextIndex >= this.targetTexts.length) { // Se já passou por todos os textos, volte ao início
          this.currentTextIndex = 0;
        }
        setTimeout(() => this.typeAndDeleteText(), 200);
      } else {
        setTimeout(() => this.typeAndDeleteText(i), typingSpeed);
      }
    }

    // Se ainda não chegou ao final do texto e não está apagando, continue digitando
    if (!this.isDeleting && i < currentTargetText.length) {
      this.text += currentTargetText.charAt(i);
      i++;
      setTimeout(() => this.typeAndDeleteText(i), typingSpeed);
    }
  }

  getRandomMs(min, max) {
    return Math.random() * (max - min) + min;
  }
}
