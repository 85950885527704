  <section class="section bg-dark">
    <div class="container">
      <div class="row">
        <div class="col-md-12 col-lg-6 nala-autopilot-text">
          <div class="section-title text-start mb-4 pb-2 text-light">
            <h4 class="title">
              Potencializando e Revolucionando o desenvolvimento de software. 
              Conheça a Nala Autopilot, nossa plataforma de IA que automatiza o desenvolvimento de software.
            </h4>
            <p class="para-desc mx-auto">

                Com a introdução da Nala Autopilot, estamos criando novas possibilidades
                para o desenvolvimento de software. Nala Autopilot é projetada para agir
                como um desenvolvedor  software seja na geração e revisão de código até
                a criação de testes automatizados a Nala Autopilot está preparada para
                lidar com diversas tarefas de desenvolvimento.
            </p>
            <ul>
                <li>Acelere seus processos de desenvolvimento</li>
                <li>Melhore a qualidade do código.</li>
                <li>Reduza custos de produção.</li>
            </ul>

          </div>
        </div>
        <!--end col-->
        <div class="nala-autopilot-planet-system  text-light">
          <app-animation-circle></app-animation-circle>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
    <!--end container-->

  </section>
