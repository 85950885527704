<!-- Hero Start -->
<section class="full-screen" id="home">
  <div class="background-video">
    <video #bgVideo [muted]="true" [autoplay]="true" loop>
      <source [src]="'assets/videos/nalaminds_bg_hero.mp4'" type="video/mp4">
      Your browser does not support the video tag.
    </video>
  </div>

  <div class="container">
    <div class="row mt-5 justify-content-center">
      <div class="col-lg-12 text-start">
        <div class="pages-heading text-white">
          <h4 class="title"> 
            <span class="">Inovação e IA para impulsionar seu</span><br/>
            <span class=""><app-typewriter></app-typewriter></span>
          </h4>
        </div>
      </div><!--end col-->
    </div><!--end row-->
  </div> <!--end container-->
</section><!--end section-->
<!-- Hero End -->

<!-- Start Section -->
<section class="section bg-dark">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-8 col-md-12">
        <div class="section-title text-start mb-4 pb-2 text-light">
          <h4 class="title">
            Bem-vindo à Nalaminds! Revolucionamos a maneira como o desenvolvimento de software é feito, com a ajuda da inteligência artificial.
          </h4>
          <p class="mx-auto">
            Nascida da <span class="fw-bold">paixão</span> pela <span class="fw-bold">inovação</span>, a <span class="fw-bold">Nalaminds</span> se propôs a criar soluções que mudam o jogo para o setor de tecnologia.
          </p>
        </div>
      </div>
      <!--end col-->
      <div class="col-lg-4 col-md-12 text-light">
        <div class="text-center">
          <img id="nala-logo" ngSrc="assets/images/nala/logos/blue_white_vert.svg" class="img-fluid" alt="logo" height="411" width="608">
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- End Section -->

<app-our-solutions></app-our-solutions>

<app-nala-autopilot></app-nala-autopilot>

<section class="section bg-light">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-4 col-md-12">
        <img src="assets/images/note.png" class="img-fluid" alt="logo" style="max-width: 80%; margin: 0 10% 10px">
      </div>
      <div class="col-lg-8 col-md-12">
        <div class="section-title text-center mb-4 pb-2">
          <h3 class="mb-4 text-dark">MUUUUITO mais do que uma assistente!</h3>
          <p class="para-desc mx-auto">
            <span class="fw-bold">Nala Autopilot</span> é uma <span class="fw-bold">extensão da sua equipe</span>, impulsionada por IA e pronta para lidar com uma variedade de tarefas de desenvolvimento. Com a Nala Autopilot, você pode <span class="fw-bold">acelerar</span> o processo de desenvolvimento, <span class="fw-bold">melhorar</span> a qualidade do código e com isso focar tarefas mais estratégicas e gerar cada vez mais valor para seu cliente!
          </p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
</section>


<!-- Start Section -->
<section class="section bg-dark">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12">
        <div class="section-title text-center mb-4 pb-2 text-light">
          <h3 class="mb-4">Descubra o futuro da produtividade</h3>
            <p class="para-desc mx-auto">
              Pronto para experimentar o <span class="fw-bold">futuro do desenvolvimento de software</span>? Entre em contato conosco para saber mais sobre o Nala Autopilot ou inscreva-se para uma demonstração gratuita.
            </p>
          <app-demo-button url="https://form.typeform.com/to/lhOJKZWe?typeform-source=b9vrx373fpj.typeform.com&utm-content=future"></app-demo-button>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- End Section -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->
