import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-animation-circle',
  templateUrl: './animation-circle.component.html',
  styleUrls: ['./animation-circle.component.css']
})

export class AnimationCircleComponent {
  @Input() navClass: string;
}
