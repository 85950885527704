<section class="section bg-light">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 text-center">
                <div class="section-title mb-4 pb-2">
                    <h4 class="title mb-4">Nossas Soluções</h4>
                    <p class="text-muted para-desc mb-0 mx-auto">
                        Conheça as soluções da <span class="text-primary fw-bold">Nalaminds</span>.<br>
                        Inteligência Artificial como você nunca viu.
                    </p>
                </div>
            </div><!--end col-->
        </div><!--end row-->
  
        <div class="row">
            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                <div class="card border-0 text-center features feature-primary feature-clean">
  
                    <div class="card-body">
                        <h5 class="mb-3">Nala Autopilot</h5>
                        <p class="text-muted">
                            Revolucione o desenvolvimento na sua empresa com a Nala Autopilot.<br>
                            Uma Inteligência Artificial pronta para se integrar à sua equipe e impulsionar a operação.
                        </p>
                        <div>
                            <a href="https://form.typeform.com/to/lhOJKZWe?typeform-source=b9vrx373fpj.typeform.com&utm-content=autopilot"  target="_blank" class="btn btn-primary fw-semibold">Saiba Mais <i class="uil uil-arrow-right"></i></a>
                        </div>
                    </div>
                </div>
            </div><!--end col-->
  
            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                <div class="card border-0 text-center features feature-primary feature-clean">
  
                    <div class="card-body">
                        <h5 class="mb-3">Nala Power Learning</h5>
                        <p class="text-muted">
                            Aprenda sobre tecnologia, desenvolva habilidades relevantes de forma eficaz e personalizada com a Nala Power Learning. <br>
                            Uma Inteligência Artificial que se adapta a você, seus funcionários, ou seus alunos. Fazendo o processo de aprendizado verdadeiramente personalizado.
                        </p>
                        <div>
                            <a href="javascript:void(0)" class="btn btn-muted muted fw-semibold">Em breve! <i class="uil uil-hourglass"></i></a>
                        </div>
                    </div>
                </div>
            </div><!--end col-->
  
            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                <div class="card border-0 text-center features feature-primary feature-clean">
  
                    <div class="card-body">
                        <h5 class="mb-3">Nala Consultancy</h5>
                        <p class="text-muted">
                            Navegue com confiança pelo mundo da inteligência artificial com nossa consultoria especializada, adequada para empresas de todos os tamanhos e estágios.
                        </p>
                        <div>
                            <a href="https://form.typeform.com/to/lhOJKZWe?typeform-source=b9vrx373fpj.typeform.com&utm-content=consultancy" target="_blank" class="btn btn-primary fw-semibold">Entre em contato <i class="uil uil-envelope"></i></a>
                        </div>
                    </div>
                </div>
            </div><!--end col-->
        </div><!--end row-->
    </div><!--end container-->
  </section>
