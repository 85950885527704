import { AfterViewInit, HostListener, ElementRef, OnDestroy, Component, ViewChild, OnInit } from '@angular/core';

@Component({
  selector: 'app-helpcenter-overview',
  templateUrl: './helpcenter-overview.component.html',
  styleUrls: ['./helpcenter-overview.component.css']
})

/**
 * Helpcenter Overview Component
 */
export class HelpcenterOverviewComponent implements OnInit {
  @ViewChild('nalaAutopilotSection') nalaAutopilotSection: ElementRef;

  // Set Topbar
  Menuoption = 'help'
  footerVariant = 'footer-seven';
  Settingicon = true
  constructor() { }

  ngOnInit(): void {
  }

  @HostListener("window:scroll", [])
  onWindowScroll() {
      let offset = window.pageYOffset;
      let bgVideo = document.querySelector('.background-video video') as HTMLElement;
      let pageHeading = document.querySelector('.pages-heading') as HTMLElement;
      
      bgVideo.style.transform = 'translateY(' + offset * 0.11 + 'px)';
      pageHeading.style.transform = 'translateY(' + offset * 0.5 + 'px)'; // Use um valor menor para uma rolagem mais lenta
      
  }

}
